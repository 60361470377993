<template>
  <div style='width: 100%'>
    <el-autocomplete
      style='width: 100%'
      :debounce='500'
      :fetch-suggestions='querySearch'
      @clear='handleSelect'
      @select='handleSelect'
      clearable
      placeholder='红人检索：输入关键字'
      v-bind='$attrs'
      v-model='userName'
      v-on='$listeners'
    >
      <!--            <i-->
      <!--                    @click="clickBtn"-->
      <!--                    class="el-icon-search"-->
      <!--                    slot="prefix">-->
      <!--            </i>-->
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'ArtistSearch',
  props: {
    size: {
      type: String,
      default() {
        return 'mini'
      }
    },
    clear: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      userName: null
    }
  },
  // computed: {
  //   debouncedQuerySearch: {
  //     get() {
  //       // 创建防抖后的方法
  //       return debounce(this.querySearch, 500)
  //     },
  //     cache: true
  //   }
  // },
  methods: {
    async querySearch(queryString, cb) {
      let { list } = await this.$api.searchArtists({ nickname: queryString })
      //数据处理
      list = list.map((item) => {
        item.value = `${item.nickname}（${item.group_name || '-'} ${item.sign_status ? (item.sign_status === 'Y' ? '『在约』' : '『解约』') : ''} 签约于 ${this.$utils.parseTime(item.sign_start, '{y}年{m}月{d}日')} ）`
        return item
      })
      cb(list)
    },
    // 处理搜索结果的选中
    handleSelect(item) {
      if (item) {
        this.$emit('update:artist-id', item ? item.id : '')
        this.$emit('update:artist-name', item ? item.nickname : '')
        this.$emit('handleSelect', item)
      } else {
        this.$emit('handleSelect', null)
      }

    },
    clearSelect: function() {
      this.userName = null
      this.$emit('handleSelect', {})
    },
    clickBtn() {
      this.$emit('clicked', this.userName)
    }
  }

}
</script>

<style scoped>

</style>
